/* global angular */

'use strict';

angular.module('managerApp').factory('passwords', () => {
    return {

        generate: (options = {}) => {

            var lowercase = 'abcdefghijklmnopqrstuvwxyz',
                    uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
                    numbers = '0123456789',
                    specials = '!@#$%&*()+_-=}{[]:;"/?.,',
                    all = lowercase + uppercase + numbers + specials;

            var regex = [
                /\d+/,
                /[A-Z]+/,
                /[a-z]+/,
                /[!@#$%&*()+_\-=}{[\]|:;"/?.,]/
            ];
            let passwordLength = (options.passwordLength) ? options.passwordLength : 8;
            options.uppercase = (options.uppercase) ? options.uppercase : false;
            options.numbers = (options.numbers) ? options.numbers : false;
            options.specials = (options.specials) ? options.specials : false;
            var pick = function (str, min, max) {
                var n, chars = '';
                if (typeof max === 'undefined') {
                    n = min;
                } else {
                    n = min + Math.floor(Math.random() * (max - min));
                }
                for (var i = 0; i < n; i++) {
                    chars += str.charAt(Math.floor(Math.random() * str.length));
                }
                return chars;
            };
            var shuffle = function (str) {
                var array = str.split('');
                var tmp, current, top = array.length;
                if (top)
                    while (--top) {
                        current = Math.floor(Math.random() * (top + 1));
                        tmp = array[current];
                        array[current] = array[top];
                        array[top] = tmp;
                    }
                return array.join('');
            };
            var password = pick(lowercase, 1);
            passwordLength--;
            if (options.uppercase) {
                password += pick(uppercase, 1);
                passwordLength--;
            }
            if (options.numbers) {
                password += pick(numbers, 1);
                passwordLength--;
            }
            if (options.specials) {
                password += pick(specials, 1);
                passwordLength--;
            }
            password += pick(all, passwordLength);
            password = shuffle(password);
            let valid = true;
            /*for (let i = 0; i < regex.length; i++) {
                valid = valid && new RegExp(regex[i]).test(password);
            }*/
            return password;
        },
        getPasswordScore: (pass) => {
            let score = 0;
            if (!pass) {
                return {
                    score: 0,
                    message: ''
                };
            }
            // award every unique letter until 5 repetitions
            var letters = new Object();
            for (var i = 0; i < pass.length; i++) {
                letters[pass[i]] = (letters[pass[i]] || 0) + 1;
                score += 5.0 / letters[pass[i]];
            }
            // bonus points for mixing it up
            var variations = {
                digits: /\d/.test(pass),
                lower: /[a-z]/.test(pass),
                upper: /[A-Z]/.test(pass),
                nonWords: /\W/.test(pass)
            };

            let variationCount = 0;
            for (var check in variations) {
                variationCount += (variations[check] === true) ? 1 : 0;
            }
            score += (variationCount - 1) * 10;

            let message = '', color = '#f00';
            if (score >= 80) {
                message = 'Very strong';
                color = '#090';
            } else if (score >= 70) {
                message = 'Strong';
                color = '#0f0';
            } else if (score >= 60) {
                message = 'Good';
                color = '#afa';
            } else if (score >= 50) {
                message = 'Medium';
                color = '#ff0';
            } else if (score >= 25) {
                message = 'Weak';
                color = '#f70';
            } else {
                message = 'Very Weak';
            }
            message = '<font color="' + color + '">' + message + '</font>';
            return {
                score: parseInt(score),
                message: message
            };
        }
    };
});
